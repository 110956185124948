import React, { useState } from "react";

const Frequently = () => {
  const [openItem, setOpenItem] = useState(null);

  const toggleAccordion = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <div className="py-80 my-100 " style={{ backgroundColor: "#E2FEFF" }}>
      <div className="row text-center">
        <div className="col-md-12 col-lg-12 col-sm-12 ">
          <h2> Frequently Asked Questions </h2>
          <p className="text-center py-2 px-3">
            {" "}
            Below you will find answers to some of the most commonly asked
            questions about our services. If you have any other inquiries, feel
            free to reach out to us.
          </p>
        </div>
      </div>
      <div className="container my-4">
        <div className="accordion">
          {/* Accordion Item 1 */}
          <div className="accordion-item border-none">
            <h2 className="accordion-header ">
              <button
                className="accordion-button border-Frequently"
                type="button"
                onClick={() => toggleAccordion(1)}
                aria-expanded={openItem === 1}
              >
                <h3>What is included in the Coorg couple package?</h3>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === 1 ? "show" : ""
              }`}
            >
              <div className="accordion-body border-Frequently">
                <p className="">
                The package includes 2 nights of accommodation, daily breakfast, transportation for sightseeing, and pick-up/drop-off services.
                Toll, Parking, driver’s Bata, road tax & fuel charges.
                </p>
              </div>
            </div>
          </div>

          {/* Accordion Item 2 */}
          <div className="accordion-item border-none mt-5">
            <h2 className="accordion-header border-Frequently">
              <button
                className="accordion-button border-Frequently"
                type="button"
                onClick={() => toggleAccordion(2)}
                aria-expanded={openItem === 2}
                style={{ backgroundColor: "#F0F7FF" }}
              >
                <h3>Can we customize the itinerary?</h3>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === 2 ? "show" : ""
              }`}
            >
              <div className="accordion-body border-Frequently">
                <p className="">
                Yes, the itinerary can be customized based on your interests, allowing you to focus on activities or relaxation as you prefer.
                </p>
              </div>
            </div>
          </div>

          {/* Accordion Item 3 */}
          <div className="accordion-item border-none mt-5">
            <h2 className="accordion-header border-Frequently">
              <button
                className="accordion-button border-Frequently"
                type="button"
                onClick={() => toggleAccordion(3)}
                aria-expanded={openItem === 3}
                style={{ backgroundColor: "#F0F7FF" }}
              >
                <h3> Are meals included besides breakfast? </h3>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === 3 ? "show" : ""
              }`}
            >
              <div className="accordion-body border-Frequently">
                <p className="">
                Only breakfast is included in the package. Lunch and dinner can be arranged at an additional cost, or you can explore local dining options during your stay.
                </p>
              </div>
            </div>
          </div>

          {/* Accordion Item 4 */}
          <div className="accordion-item border-none mt-5">
            <h2 className="accordion-header border-Frequently">
              <button
                className="accordion-button border-Frequently"
                type="button"
                onClick={() => toggleAccordion(4)}
                aria-expanded={openItem === 4}
                style={{ backgroundColor: "#F0F7FF" }}
              >
                <h3>Is the package suitable for special occasions like anniversaries or honeymoons?</h3>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === 4 ? "show" : ""
              }`}
            >
              <div className="accordion-body border-Frequently">
                <p className="py-1">
                Yes, this package is ideal for couples celebrating special occasions, providing a romantic atmosphere and personalized experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frequently;
