import React from 'react';
import images from '../../images/images';
import { NavLink } from 'react-router-dom';

function Header() {
    return (
        <div className="container py-3">
            <div className="row justify-content-between align-items-center">
                <div className="col-12 col-md-auto">
                    <div className="Logo d-flex align-items-center">
                    <a href="https://bookonetour.com/">
                        <img src={images.logo} alt="Logo" className="logo-img" />
                    </a>
                    </div>
                </div>
                <div className="col-12 col-md-auto d-flex justify-content-center mt-3 mt-md-0">
                    <div className="d-flex flex-column flex-md-row align-items-center">
                        {/* Instagram Button */}
                        <button className="btn-header insta-btn d-flex align-items-center mb-2 mb-md-0 me-md-2 d-none d-md-flex">
                            <i className="fab fa-instagram px-1" style={{ fontSize: '24px' }}></i>
                            {/* Hidden on mobile, visible on larger screens */}
                            <span className="ms-1 d-none d-md-block Social-media">
                                <NavLink to="https://www.instagram.com/bookonetour/" target="_blank">BOOKONETOUR</NavLink>
                            </span>
                        </button>
                        {/* WhatsApp Button */}
                        <button className="btn-header insta-btn d-flex align-items-center mb-2 mb-md-0 me-md-2 d-none d-md-flex">
                            <i className="fab fa-whatsapp px-1" style={{ fontSize: '24px' }}> </i>
                            <span className="ms-1 d-none d-md-block Social-media">
                                <NavLink to="https://wa.me/8148818018" target="_blank">+91 81488 18018</NavLink>
                            </span>
                            {/* Hidden on larger screens, visible on mobile */}
                           
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
