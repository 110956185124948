import React, { useState } from "react";
import images from "../../images/images";

const Itinerary = () => {
  const [loadingStates, setLoadingStates] = useState([
    [true, true, true, true, true], // Day 1: 5 images
    [true, true, true, true], // Day 2: 4 images
    [true, true, true, true], // Day 3: 4 images
  ]);

  const [openItem, setOpenItem] = useState(null);

  const toggleAccordion = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  const handleImageLoad = (dayIndex, imageIndex) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[dayIndex] = [...newStates[dayIndex]];
      newStates[dayIndex][imageIndex] = false; // Set image as loaded
      return newStates;
    });
  };

  const imageUrls = [
    [
      images.day101,
      images.day102,
      images.day103,
      images.day104,
      images.day105,
      images.day106,
    ],
    [
      images.day201,
      images.day202,
      images.day203,
      images.day204,
      images.day205,
      images.day206,
    ],
    [
      images.day301,
      images.day302,
      images.day303,
      images.day304,
      images.day305,
    ],
  ];

  const days = [
    "Day 01 : Arrival at Coorg (Services: Stay & Car)",
    "Day 02 : Coorg Full day Sightseeing (Services: Stay, Breakfast & Car)",
    "Day 03 : Departure Coorg (Services: Breakfast & Car)",
  ];

  const day1Buttons = [
    "Titian Monastery / Golden Temple",
    "Nisargadhama Forest Park",
    "Dubare Elephant Camp",
    "White Water River Rafting",
    "Overnight Hotel Stay",
  ];

  const day2Buttons = [
    "Talakaveri Temple / Waterfalls",
    "Triveni Sangam",
    "Sri Bhagandeshwara Temple",
    "Raja Seat",
    "Omkareshwara Temple",
    "Madikeri Fort",
    "Overnight Hotel Stay",
  ];

  const day3Buttons = [
    "Harangi Dam",
    "Abbey Falls",
    " Shopping @ Coorg",
    "Departure",
  ];

  const buttonsForDays = [day1Buttons, day2Buttons, day3Buttons];

  return (
    <div className="container pt-3 px-4 pb-80">
      <div className="row">
        <div className="col">
          <h2 className="py-4">
            Coorg 2N3D Package <span className="text-warning">Itinerary</span>
          </h2>
        </div>
      </div>
      <div className="accordion">
        {days.map((dayTitle, dayIndex) => (
          <div key={dayIndex} className="accordion-item border-none mt-5">
            <h2 className="accordion-header">
              <button
                className="accordion-button border-none"
                type="button"
                onClick={() => toggleAccordion(dayIndex + 1)}
                aria-expanded={openItem === dayIndex + 1}
              >
                <h5>{dayTitle}</h5>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === dayIndex + 1 ? "show" : ""
              }`}
            >
              <div className="accordion-body">
                <div className="button-list py-3 d-none d-md-block">
                  {buttonsForDays[dayIndex].map((label, index) => (
                    <div key={index} className="button-custom">
                      <button className="custom-btn">{label}</button>
                    </div>
                  ))}
                </div>

                <p className="py-4">
                  {dayIndex === 0 && (
                    <>
                    Upon Reaching Coorg stop, our team will greet you and transfer you to the Coorg, Check-in to Coorg hotel, 
                    After fresh-up proceed to visit following Coorg Sightseeing, Titian Monastery / Golden Temple, 
                    Nisargadhama Forest Park, Dubare Elephant Camp, White Water River Rafting, Stay overnight at hotel. {" "}
                      {/* {dayTitle.toLowerCase()}. */}
                    </>
                  )}
                  {dayIndex === 1 && (
                    <>
                    Today morning breakfast to Hotel and Visit to Coorg sightseeing place, Talakaveri Temple / Waterfalls, 
                    Triveni Sangam, Sri Bhagandeshwara Temple, Raja Seat, Omkareshwara Temple, Madikeri Fort, Stay overnight at hotel. {" "}
                      {/* {dayTitle.toLowerCase()}. */}
                    </>
                  )}
                  {dayIndex === 2 && (
                    <>
                    Morning after Breakfast, check-out from the Coorg hotel. Depending on your departure time, if time permits, 
                    you can visit the following Coorg tourismplaces enroute : Harangi Dam, Abbey Falls, Shopping @ Coorg, 
                    Drop at Coorg Bus Stand for your return journey. Home town with a full of memories. The tour ends here {" "}
                      {/* npm{dayTitle.toLowerCase()}. */}
                    </>
                  )}
                </p>

                <div className="row mt-3">
                  {imageUrls[dayIndex].map((url, imageIndex) => (
                    <div key={imageIndex} className="col-6 col-md-2 mb-3">
                      {loadingStates[dayIndex][imageIndex] && (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      <img
                        src={url}
                        alt={`img${imageIndex + 1}`}
                        className="img-fluid"
                        onLoad={() => handleImageLoad(dayIndex, imageIndex)}
                        style={{
                          display: loadingStates[dayIndex][imageIndex]
                            ? "none"
                            : "block",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Itinerary;
