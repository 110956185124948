
import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import images from '../../images/images';

const packages = [
  {
  id: 1,
    title: "Ooty 2N3D Couple Package",
    description: "A picturesque town nestled within the lush green hills of the Nilgiri Hills,.....",
    price: "₹4,999",
    imageUrl: images.chikpack,
    person: "per person",
  },
  {
    id: 2,
    title: "Kodaikanal 2N3D Couple Package",
    description: "Kodaikanal is a beautiful hill station with stunning landscapes and cool weather.",
    price: "₹4,999",
    imageUrl: images.kodaikanalpack,
    person: "per person",
  },
  {
    id: 3,
    title: "Yercaud 2N3D Couple Package",
    description: "Yercaud is a serene hill station in Tamil Nadu, famous for its coffee plantation.....",
    price: "₹4,999",
    imageUrl: images.yercaudpack,
    person: "per person",
  },
  {
    id: 4,
    title: "Munnar 2N3D Couple Package",
    description: "Munnar is a scenic hill station in Kerala, renowned for its sprawling tea plantation.....",
    price: "₹6,999",
    imageUrl: images.munnarpack,
    person: "per person",
  },
  {
    id: 5,
    title: "Chikmagalur 2N3D Couple Pack",
    description: "Chikmagalur is a lovely hill station known for its coffee plantations and lush scenery.",
    price: "₹7,500",
    imageUrl: images.coorgpack,
    person: "per person",
  },
  {
    id: 6,
    title: "Wayanad 2N3D Package",
    description: "Wayanad is a picturesque district in Kerala, known for its lush forests, waterfalls.....",
    price: "₹6,999",
    imageUrl: images.wayanadpack,
    person: "per person",
  },
  {
    id: 7,
    title: "Manali 3N4D Couple Package",
    description: "Manali is a popular hill station in Himachal Pradesh, renowned for its stunning mount.....",
    price: "₹13,000",
    imageUrl: images.manalipack,
    person: "per person",
  },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(3); // Default to 3 cards for desktop

  useEffect(() => {
    // Adjust visibleCards based on screen width
    const updateVisibleCards = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setVisibleCards(1); // Show 1 card on mobile
      } else if (width < 992) {
        setVisibleCards(2); // Show 2 cards on tablets
      } else {
        setVisibleCards(3); // Show 3 cards on desktop
      }
    };

    updateVisibleCards(); // Initial call
    window.addEventListener('resize', updateVisibleCards); // Add event listener for resize

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateVisibleCards);
  }, []);

  const next = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % packages.length); // Loop back to start
  };

  const prev = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + packages.length) % packages.length);
  };

  useEffect(() => {
    const intervalId = setInterval(next, 5000); // 5000 milliseconds = 5 seconds
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [currentIndex]);

  return (
    <div className="container pb-5">
      <div className="row">
        <div className="col text-center">
          <h2>Related Couple Packages</h2>
           <p className="text-center">
            Discover our curated couple packages designed to offer unforgettable experiences
            Explore options that cater to your preferences and make your moments special.
           </p>
        </div>
      </div>
      <div className="row align-items-center">
        {/* Previous Button */}
        <button
          className="btn col-auto d-flex align-items-center justify-content-center d-none d-md-flex "
          onClick={prev}
          disabled={currentIndex === 0}
          style={{ border: "0px", visibility: visibleCards === 1 ? 'hidden' : 'visible' }}
        >
          <img src={images.leftcircle} alt="Previous" className="hide-mobile" />

        </button>

        {/* Carousel Cards */}
        <div className="col py-4">
          <div className="row">
            {packages.slice(currentIndex, currentIndex + visibleCards).map(pkg => (
              <div key={pkg.id} className="col-md-4 col-sm-12 col-lg-4 mb-4">
                <div className="carousel__card card">
                  <img
                    src={pkg.imageUrl}
                    alt={pkg.title}
                    className="carousel__image card-img-top"
                    style={{ width: '100%', height: 'auto' }}
                  />
                  <div className="carousel__content card-body">
                    <h3 className="carousel__package-title">{pkg.title}</h3>
                    <p className="carousel__description">{pkg.description}</p>
                    <div className='viewpoints-icons'>

    <li><img src={images.package1} alt="" /> <br />
<span className='icon-text'>PRIVATE CAB</span>  </li>
    <li><img src={images.package2} alt="" /> <br />
  <span className='icon-text'>SIGHTSEEING</span>  </li>
    <li><img src={images.package3} alt="" /> <br />
    <span className='icon-text'>HOTEL STAY</span></li>
    <li><img src={images.package4} alt="" /> <br />
    <span className='icon-text'>BREAKFAST</span></li>
    <li><img src={images.package5} alt="" /> <br />
   <span className='icon-text'> TOUR GUIDE</span></li>

</div>

                    <h3 className="carousel__price">
                      {pkg.price} <span className="Person-rate">{pkg.person}</span>
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Next Button */}
        <button
          className="btn col-auto d-flex align-items-center justify-content-center d-none d-md-flex  "
          onClick={next}
          disabled={currentIndex + visibleCards >= packages.length}
          style={{ border: "0px", visibility: visibleCards === 1 ? 'hidden' : 'visible' }}
        >
          <img src={images.Rightcircle} alt="Next" className="hide-mobile" />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
