

import React, { useState, useEffect } from 'react';
import images from '../../images/images'; // Import the images

const ReviewCard = ({ name, location, review, rating, avatar }) => {
  return (
    <div className="col-9 col-md-4 col-lg-3 mb-4">
      <div className="card shadow-sm p-3 bg-white rounded">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <img
              src={avatar}
              alt={`${name}'s avatar`}
              className="avatar rounded-circle"
              style={{ width: '44px', height: '44px' }}
            />
            <div className="ml-3 ">
              <h5 className="card-title">{name}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{location}</h6>
            </div>
          </div>
          <p className="card-text pt-3">{review}</p>
          <div className="d-flex justify-content-between align-items-center rating-container">
            <div>
              {Array.from({ length: rating }).map((_, index) => (
                <span key={index} className="text-warning">
                  ★
                </span>
              ))}
            </div>
            <img
              src={images.Google}
              alt="Google logo"
              style={{ width: '24px', height: 'auto' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const TestimonialCarousel = () => {
  const [chunkSize, setChunkSize] = useState(1); // One card per view
  const [currentIndex, setCurrentIndex] = useState(0);

  const reviews = [
    {
      name: 'Raghul. R',
      location: 'Ambattur, Chennai',
      review:
        'Every customer is very important to us and we strive hard to keep each and every one of our customers happy. Good service, pickup drop perfect timing best service book one tour travels for Ooty drivers also best persons. Thank you so much for being our wonderful customer!',
      rating: 5,
      avatar: images.person,
    },
    {
      name: 'Sailaja.A',
      location: 'Mumbai, India',
      review:
        'Recently, we traveled with Book One Tour. The arrangements were amazing, and it was a wonderful experience for us. All my family members loved the trip. Thanks to the Book One Tour team for the excellent arrangements and great value for money. I highly recommend.....',
      rating: 5,
      avatar: images.person2,
    },
    {
      name: 'Irfhan Abdul',
      location: 'Nungambakkam, Chennai',
      review:
        'Thanks to for the excellent and great value for money Were glad you had a positive experience with Book One Tour Travels for our Ooty trip, and we are equally proud of our drivers for making your travel comfortable and pleasant. Best Tours packages ever',
      rating: 4,
      avatar: images.person3,
    },
    {
      name: 'Ajay Kumar',
      location: 'Thirunelveli, Tamilnadu',
      review:
        'Thank you for choosing our services for your trip to Ooty. We take great pride in our team of professional drivers who strive to make your travel comfortable and pleasant. Your trust means the world to us, and we are grateful for the opportunity to serve you!',
      rating: 5,
      avatar: images.person,
    },
    {
      name: 'John Ruskin',
      location: 'Bangalore, India',
      review:
        'As  once said Quality is never an accident; it is always the result of intelligent effort. We re grateful to have such wonderful customers like you, and your appreciation fuels our drive to continue delivering the best service possible. Thank you for your trust and kind words!',
      rating: 5,
      avatar: images.person2,
    },
    {
      name: 'Ananya S.',
      location: 'Mumbai, India',
      review:
        'we work hard to make your journey enjoyable. We re glad you had a positive experience with Book One Tour Travels for your Ooty trip, and we are equally proud of our drivers for making your travel comfortable and pleasant.  Thank you Bookonetours.',
      rating: 4,
      avatar: images.person3,
    },
    {
      name: 'lavanya priscilla',
      location: 'Chennai, India',
      review:
        'One of the best and safest tour package for girls..enjoyed the trip and also the executives were available all the time if anything needed. Trusted Tour Package company.Thank you BookOnTour for their and service and pricing, highly recommended travels company',
      rating: 5,
      avatar: images.person,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setChunkSize(window.innerWidth < 768 ? 1 : 3); // Adjust chunk size based on screen width
    };

    handleResize(); // Set initial chunk size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const reviewsChunks = reviews.slice(currentIndex, currentIndex + chunkSize);

  const next = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const prev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(next, 5000); // Move to next review every 3 seconds
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [currentIndex]);

  return (
    <div className="container">
      <div className="row text-center pb-5">
        <div className="col">
          <h2>See what our clients say</h2>
          <p className="text-center">
            Our clients appreciate the quality of service we provide, and we take pride in ensuring satisfaction.
          </p>
        </div>
      </div>
      <div className="carousel slide">
        <div className="carousel-inner">
          <div className="row justify-content-center">
            {reviewsChunks.map((review, i) => (
              <ReviewCard key={i} {...review} />
            ))}
          </div>
        </div>
        {/* Custom Controls */}
        <button
          className="carousel-control-prev d-none d-md-block" // Hide on mobile
          type="button"
          onClick={prev}
          style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next d-none d-md-block" // Hide on mobile
          type="button"
          onClick={next}
          style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0' }}
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
