import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

function Form() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
    destination: "",
    people: "",
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // WhatsApp number to which the message will be sent (include country code)
    const whatsappNumber = "918148818018"; // Include country code (91 for India)


    // Formatting the form data into a message
    const message = `Name: ${formData.name}
    Email: ${formData.email}
    Phone: ${formData.phone}
    Date: ${formData.date}
    Destination: ${formData.destination}
    People: ${formData.people}`;

    // Creating WhatsApp URL
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;

    // Opening WhatsApp with the pre-filled message
    window.open(whatsappUrl, '_blank');

    // Clear the form after opening WhatsApp
    setFormData({
      name: "",
      email: "",
      phone: "",
      date: "",
      destination: "",
      people: "",
    });

    handleClose(); // Close the modal after submission
  };

  const formContent = (
    <form onSubmit={handleSubmit} className="w-100">
      <div className="mb-4">
        <input
          type="text"
          className="form-control py-2"
          placeholder="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <input
          type="email"
          className="form-control py-2"
          placeholder="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <input
          type="number"
          className="form-control py-2"
          placeholder="Phone Number"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <select
          className="form-control py-2"
          name="destination"
          value={formData.destination}
          onChange={handleChange}
          required
        >
          <option value="">Places of Destination</option>
          <option value="kodaikanal">kodaikanal</option>
          <option value="ooty">Ooty</option>
          <option value="Coorg">Coorg</option>
          <option value="Kerala">Chikamagalur</option>   
          <option value="Kerala">Kerala</option>
          <option value="Manali">Munnar</option>
          <option value="Manali">Manali</option>
          {/* Add more options as needed */}
        </select>
      </div>
      <div className="mb-4">
        <input
          type="date"
          className="form-control py-2"
          name="date"
          placeholder="Date"
          value={formData.date}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <input
          type="number"
          className="form-control py-2"
          placeholder="Number of People"
          name="people"
          value={formData.people}
          onChange={handleChange}
          min="1"
          max="10"
          required
        />
      </div>

      <button type="submit" className="btn btn-warning w-100  form-head">
        SUBMIT
      </button>
    </form>
  );

  return (
    <>
      {isMobile ? (
        <>
          <button className="btn btn-warning    form-head fixed-bottom m-3 py-2" onClick={handleShow}>
            Enquiry Form
          </button>

          <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
              <Modal.Title>GET QUOTE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {formContent}
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <div className="container bg-info px-4 py-5">
          <div className="row">
            <div className="col-12">
              <h2 className="form-head mb-3">Get Your Customized Package !</h2>
              {formContent}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Form;
