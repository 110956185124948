
import Logo from "../Assets/images/logo.png";
import luggage from "../Assets/images/luggage 1.svg";
import booking from "../Assets/images/booking 1.svg";
import customer from "../Assets/images/best-customer-experience 1.svg";
import reward from "../Assets/images/reward 1.svg";
import Group from "../Assets/images/Group 1341.svg";
import package1 from "../Assets/images/a (1).png";
import package2 from "../Assets/images/a (2).png";
import package3 from "../Assets/images/a (3).png";
import package4 from "../Assets/images/a (4).png";
import package5 from "../Assets/images/a (5).png";
import point from "../Assets/images/point.svg";
import download from "../Assets/images/download.svg";
import iconlink from "../Assets/images/Icon Frame.svg";
import iconlink2 from "../Assets/images/mail.svg";
import iconlink3 from "../Assets/images/icons link.svg";
import Google from "../Assets/images/Google.png";

import Rightcircle from "../Assets/images/Right circle.svg"
import leftcircle from "../Assets/images/Left circle.svg";
import green from "../Assets/images/green.svg";
import person from "../Assets/images/person.png";
import person2 from "../Assets/images/person2.png";
import person3 from "../Assets/images/person3.png";
import Packagedownload  from  "../Assets/images/package download.svg";

import banner1 from "../Assets/images/banner1.png";
import banner2 from "../Assets/images/banner2.png";
import banner3 from "../Assets/images/banner3.png";

import day101 from "../Assets/images/day1-01.png";
import day102 from "../Assets/images/day1-02.png";
import day103 from "../Assets/images/day1-03.png";
import day104 from "../Assets/images/day1-04.png";
import day105 from "../Assets/images/day1-05.png";
import day106 from "../Assets/images/day1-06.png";

import day201 from "../Assets/images/day2-01.png";
import day202 from "../Assets/images/day2-02.png";
import day203 from "../Assets/images/day2-03.png";
import day204 from "../Assets/images/day2-04.png";
import day205 from "../Assets/images/day2-05.png";
import day206 from "../Assets/images/day2-06.png";

import day301 from "../Assets/images/day3-01.png";
import day302 from "../Assets/images/day3-02.png";
import day303 from "../Assets/images/day3-03.png";
import day304 from "../Assets/images/day3-04.png";
import day305 from "../Assets/images/day3-05.png";

import wayanadpack from "../Assets/images/wayanadpackage.png";
import manalipack from "../Assets/images/manalipackage.png";
import coorgpack from "../Assets/images/coorgpackage.png";
import chikpack from "../Assets/images/chikpackage.png";
import kodaikanalpack from "../Assets/images/kodaikanalpackage.png";
import munnarpack from "../Assets/images/munnarpackage.png";
import yercaudpack from "../Assets/images/yercaudpackage.png";

import highprice001 from "../Assets/images/highprice001.png";
import highprice002 from "../Assets/images/highprice002.png";
import highprice003 from "../Assets/images/highprice003.png";

const images = {
    logo: Logo,
    banner1:banner1,
    banner2:banner2,
    banner3:banner3,
    luggage:luggage,
    booking:booking,
    customer:customer,
    reward:reward,
    Group:Group,
   package1:package1,
   package2:package2,
   package3:package3,
   package4:package4,
   package5:package5,
   point:point,
   download:download,
   iconlink:iconlink,
   iconlink2:iconlink2,
   iconlink3:iconlink3,
   Google:Google,
   day101:day101,
   day102:day102,
   day103:day103,
   day104:day104,
   day105:day105,
   day106:day106,
   day201:day201,
   day202:day202,
   day203:day203,
   day204:day204,
   day205:day205,
   day206:day206,
   day301:day301,
   day302:day302,
   day303:day303,
   day304:day304,
   day305:day305,
   
   wayanadpack:wayanadpack,
   manalipack:manalipack,
   coorgpack:coorgpack,
   chikpack:chikpack,
   kodaikanalpack:kodaikanalpack,
   munnarpack:munnarpack,
   yercaudpack:yercaudpack,

   highprice001:highprice001,
   highprice002:highprice002,
   highprice003:highprice003,

   Rightcircle:Rightcircle,
   leftcircle:leftcircle,
   green:green,
   person:person,
   person2:person2,
   person3:person3,
   packagedownload:Packagedownload,
//    Related:Related,
//    Related2:Related2,
//    Related4:Related4,
   
};

export default images;